import { OS, getOS } from "./helpers/getOS";
import { Resolution, getResolution } from "./helpers/getResolution";
import { isMatchingType } from "./helpers/matcher";
import "./style.css";

const showContainer = (elem: Element) => {
  elem.classList.add("active");
};

export const showContainerByDevice = (os: OS, resolution: Resolution) => {
  const containers = document.querySelectorAll(
    "[data-default-or-rule='match_device_types']",
  );

  const defaultContainer = document.querySelectorAll(
    "[data-default-or-rule='default']",
  );

  containers.forEach((container) => {
    const osType = container.getAttribute("data-os-type")?.split(",");
    const deviceType = container.getAttribute("data-device-type")?.split(",");

    if (isMatchingType(os, osType) && isMatchingType(resolution, deviceType)) {
      showContainer(container);
      return;
    }
  });

  if (defaultContainer.length > 0) {
    defaultContainer.forEach((container) => showContainer(container));
  }
};

showContainerByDevice(getOS(), getResolution());
