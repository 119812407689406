import { breakpoints } from "../vars";
import { getOS } from "./getOS";

export type Resolution = "mobile" | "desktop" | "tablet" | undefined;

export const getResolution = (): Resolution => {
  const queries = {
    mobile: "all and (max-width: " + (breakpoints.md - 1) + "px)",
    tablet:
      "all and (min-width: " +
      breakpoints.md +
      "px) and (max-width: " +
      (breakpoints.lg - 1) +
      "px)",
    desktop: "all and (min-width: " + breakpoints.lg + "px)",
  };

  if (window.matchMedia(queries.mobile).matches) {
    return "mobile";
  }

  if (window.matchMedia(queries.tablet).matches) {
    return "tablet";
  }

  if (window.matchMedia(queries.desktop).matches) {
    // handle iPad Pro as tablet device
    const os = getOS();

    return os === "android" || os === "ios" ? "tablet" : "desktop";
  }

  return undefined;
};
