export type OS = "android" | "ios" | undefined;

export const getOS = (): OS => {
  const userAgent = window.navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  // http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
    return "ios";
  }

  return undefined;
};
