const isMatchingType = (
  type: string | undefined,
  types: string[] | undefined,
) => {
  if (!types || !types.length) {
    return;
  }

  return (
    types.length === 0 || types[0] === "" || (type && types.includes(type))
  );
};

export { isMatchingType };
